
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Widget } from '@govflanders/mbp-admin-panel-shared';

@Component
export default class WidgetMetaData extends Vue {
  /**
   * Holds the widget config for which metadata should be shown.
   *
   * @var {Widget}
   */
  @Prop({
    type: Object,
    required: true,
  })
  public value!: Widget;
}
